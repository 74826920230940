import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Alert, AlertIcon, Box, Button, Input, InputGroup, InputLeftAddon, Stack, Text } from '@chakra-ui/react';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { sendLeadToVakas } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';
import { Typography } from 'antd';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useAsyncFn } from 'react-use';
import Checkbox from 'antd/lib/checkbox/Checkbox';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const utm_term = '(КУПОН 7000)';
const utm_campaign = '(КУПОН 7000)';
const budget = 1990;

const getQueryParams = () => {
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  console.log(window.location);
  const keys = Array.from(searchParams.keys());
  return keys.map(x => ({ [x]: searchParams.get(x) }));
};

export const Home = observer(() => {
  console.log(getQueryParams());
  const [error, setError] = useState<boolean>();
  const [errorText, setErrorText] = useState<string | null>(null);
  //* ==================
  const [phone, setPhone] = useState<string>('');
  const [cb, setCH] = useState<boolean>(false);
  //* ==================

  const [promocode, setPromocode] = useState<string>('');
  const [formEnable, setFormEnable] = useState<boolean>(false); // ! Включение промокода

  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'small-zone');
  }, []);

  //* ==================
  const history = useHistory();

  const onSubmit = async () => {
    try {
      await sendLeadToVakas({
        budget,
        phone: '+7' + phone,
        utm_term,
        utm_source: window.location.hostname,
        utm_campaign,
        cb,
      }).then(async () => {
        history.push(Routes.Success);
      });
    } catch (error) {
      console.log(error);
      history.push(Routes.Error);
    }
  };

  const [status, checkPromocode] = useAsyncFn(async () => {
    return new Promise(x => {
      setTimeout(() => {
        if (promocode === 'УВЕРЕННОСТЬ') {
          setFormEnable(true);
        } else {
          setErrorText('Вы ввели неверный ответ');
          setError(true);
        }
        x(true);
      }, 2000);
    });
  }, [formEnable, promocode]);

  return (
    <AppTemplate
      formEnable={formEnable}
      headerTitle='Тест драйв'
      headerBody={
        formEnable ? (
          <Text style={{ textAlign: 'center', fontSize: 14 }}>
            <br />
            🎁Поздравляем! Вы выиграли подарок! <br /> <br />
            🥳Вы получаете малую зону* в подарок при посещении следующей процедуры! <br />
            🤩А Ваша подруга получает, благодаря Вам, карту ML CLUB на 3 месяца в подарок! Она сможет ее забрать при
            посещении первой процедуры в нашей клинике! <br /> <br />
            *В малую зону входит любая из следующих зон на выбор: Верхняя губа, Подбородок, Белая линия живота,
            Межбровье <br />
            Воспользоваться подарком можно в комплексе с любой процедурой, за исключением малой зоны. <br /> <br />
            ❗️Хотите отправить подруге СМС от нашей компании и получить свой подарок? Нажмите кнопку "хочу"! <br />
            <br />
          </Text>
        ) : (
          <Text style={{ textAlign: 'center', fontSize: 14 }}>
            <br />
            Что самое ценное приобретает клиент Миссис Лазер, когда проходит процедуру?
            <br />
            <br />
          </Text>
        )
      }
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            {formEnable ? (
              <Typography.Title level={5} style={{ textAlign: 'center' }}>
                Ваши контактные данные
              </Typography.Title>
            ) : (
              <Typography.Title level={5} style={{ textAlign: 'center' }}>
                Введите ответ
              </Typography.Title>
            )}
            {formEnable ? (
              <>
                <InputGroup>
                  <InputLeftAddon children='+7' />
                  <Input
                    maxLength={10}
                    type='tel'
                    /* isInvalid={error} */
                    placeholder='Введите ваш телефон'
                    value={phone}
                    onChange={event => {
                      isNumeric(event.target.value) && setPhone(event?.target.value);
                      setError(false);
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <Checkbox value={cb} onChange={event => setCH(event.target.checked)}>
                    Хочу
                  </Checkbox>
                </InputGroup>
              </>
            ) : (
              <InputGroup>
                <Input
                  maxLength={30}
                  /* isInvalid={error} */
                  placeholder='Введите ответ'
                  value={promocode}
                  onChange={e => {
                    setPromocode(e.target.value.toUpperCase());
                    setError(false);
                  }}
                />
              </InputGroup>
            )}
          </Stack>
          {error && (
            <Stack spacing='1rem' style={{ marginTop: 12 }}>
              {errorText && (
                <Alert status='error'>
                  <AlertIcon />
                  {errorText}
                </Alert>
              )}
            </Stack>
          )}
          <Button
            isLoading={status.loading}
            bgColor={formEnable ? undefined : 'blue.600'}
            onClick={() => {
              if (formEnable) {
                if (phone === '') {
                  setError(true);
                  setErrorText(phone === '' ? 'Вы не указали свой номер' : null);
                } else {
                  onSubmit();
                }
              } else {
                checkPromocode();
              }
            }}
            mt='24px'
            w='100%'
            h='60px'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
            style={{ marginBottom: 16 }}
          >
            {formEnable ? 'Получить подарок' : 'Проверить'}
          </Button>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
